/* website: 3453-kiaofowensoud-preferred
 * created at 2021-06-14 15:30 by fbbreard
 */

// Import all makes and organization styles files
@import "../templates/makes/kia.scss";
@import "../utils/icons.scss";

.widget-sr{
  &.dealer__kia-of-owen-sound{
    .showroom-vdp-used{
      a.sr-button-2.is-fluid.vehicle-cta-btn{
        display: none;
      }
    }
    .showroom-build-and-price{
      a.sr-button-1.is-fluid.vehicle-cta-btn{
        display: none;
      }
    }
  }
}
